// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-single-js": () => import("./../../../src/templates/BlogPostSingle.js" /* webpackChunkName: "component---src-templates-blog-post-single-js" */),
  "component---src-templates-blog-posts-js": () => import("./../../../src/templates/BlogPosts.js" /* webpackChunkName: "component---src-templates-blog-posts-js" */),
  "component---src-templates-portfolio-post-single-js": () => import("./../../../src/templates/PortfolioPostSingle.js" /* webpackChunkName: "component---src-templates-portfolio-post-single-js" */),
  "component---src-templates-portfolio-posts-js": () => import("./../../../src/templates/PortfolioPosts.js" /* webpackChunkName: "component---src-templates-portfolio-posts-js" */),
  "component---src-templates-resume-posts-js": () => import("./../../../src/templates/ResumePosts.js" /* webpackChunkName: "component---src-templates-resume-posts-js" */)
}

